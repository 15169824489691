export enum PageRoutes {
  theCamp = '/the-camp',
  whoWeAre = '/the-camp/who-are-we',
  camp67AndKumbh = '/the-camp/camp67-and-kumbh',
  camp67AndTheLand = '/the-camp/camp67-and-the-land',
  kumbh2025 = '/kumbh-2025',
  significanceOfKumbh = '/kumbh-2025/significance-of-kumbh',
  achalSaptami = '/kumbh-2025/achal-saptami',
  basantPanchami = '/kumbh-2025/basant-panchami',
  maghiPurnima = '/kumbh-2025/maghi-purnima',
  mahaShivratri = '/kumbh-2025/maha-shivratri',
  makarSankranti = '/kumbh-2025/makar-sankranti',
  mauniAmavasya = '/kumbh-2025/mauni-amavasya',
  paushPurnima = '/kumbh-2025/paush-purnima',
  dormSingle = '/packages/dormitory-single',
  dormBunk = '/packages/dormitory-bunk',
  twinRoom = '/packages/twin-room',
  familyRoom = '/packages/family-room',
  familyRoomPlus = '/packages/family-room-plus',
  contactUs = '/contact-us',
  packages = '/packages',
  reservation = '/packages/reservation',
}
